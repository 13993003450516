// ELEMENTS
const app = {
  body: document.querySelector("body"),
  burger: document.querySelector(".header_burger"),
  header: document.querySelector(".header"),
  anim: document.querySelector(".anim-burger"),
  nav: document.querySelector(".header_nav"),
  cookie: document.querySelector(".cookie"),
  pnav: document.querySelector(".product_nav"),
  cookieYes: document.querySelector(".cookie_btns_yes"),
  cookieNo: document.querySelector(".cookie_btns_no"),
  menuItm: document.querySelectorAll(".header_nav_list_link"),
  readMore: document.querySelectorAll(".tries_card_right_btn"),
  productReadMore: document.querySelectorAll(".product_card_btn"),
  more: document.querySelectorAll(".tries_card_right_txt"),
  tbanner: document.querySelector(".tries_banner_src"),
  switch: document.querySelectorAll(".switch"),
  productSpec: document.querySelectorAll(".product_card_specs"),
  revName: document.querySelectorAll(".revmap_list_ctn_itm_name"),
  revPop: document.querySelectorAll(".ol-selectable"),
  credit: document.querySelector(".ol-attribution")
};

// HANDLE BURGER DISPLAY
const burgerDisplay = e => {
  app.anim.classList.toggle("active");
  app.nav.classList.toggle("b-opened");
};

const burgerClear = e => {
  app.anim.classList.remove("active");
  app.nav.classList.remove("b-opened");
};

for (var i = 0; i < app.menuItm.length; i++) {
  app.menuItm[i].addEventListener("click", burgerClear);
}

app.burger.addEventListener("click", burgerDisplay);
window.addEventListener("load", burgerClear);
window.addEventListener("resize", burgerClear);

// HANDLE MARGIN TOP OF HEADER ACCORDINGLY WITH THE WP ADMIN HEADER
const marginTopHandler = e => {
  let st = window.scrollY;
  let w = window.innerWidth;

  let logged = app.body.classList.contains("logged-in");

  if (logged) {
    if (st > 0 && w <= 600) {
      app.header.style.marginTop = "0";

      if (app.pnav !== null) {
        app.pnav.style.marginTop = "0";
      }
    } else if (st === 0 && w <= 600 && w <= 782) {
      app.header.style.marginTop = "46px";

      if (app.pnav !== null) {
        app.pnav.style.marginTop = "46px";
      }
    } else if (st === 0 && w >= 782) {
      app.header.style.marginTop = "32px";

      if (app.pnav !== null) {
        app.pnav.style.marginTop = "32px";
      }
    }
  }
};

window.addEventListener("load", marginTopHandler);
window.addEventListener("scroll", marginTopHandler);
window.addEventListener("resize", marginTopHandler);

// O P E N  T R I E S
const tryDisplay = e => {
  let textId = e.target.id.split("_")[1];

  app.more[textId].classList.toggle("c-open");

  if (window.location.href.includes("/en/")) {
    app.switch[textId].innerHTML === "Close"
      ? (app.switch[textId].innerHTML = "Read More")
      : (app.switch[textId].innerHTML = "Close");
  } else {
    app.switch[textId].innerHTML === "Fermer"
      ? (app.switch[textId].innerHTML = "Lire plus")
      : (app.switch[textId].innerHTML = "Fermer");
  }
};

// O P E N  S P E C S
const specsDisplay = e => {
  let textId2 = e.target.id.split("_")[1];

  app.productSpec[textId2].classList.toggle("c-open");

  if (app.productReadMore[textId2].classList.value.includes('go-anchor')) {
    let url = location.href
    location.href = "#product_" + textId2
    history.replaceState(null, null, url)
  }

  app.productReadMore[textId2].classList.toggle('go-anchor');

  if (window.location.href.includes("/en/")) {
    app.switch[textId2].innerHTML === "Specifications"
      ? (app.switch[textId2].innerHTML = "Hide")
      : (app.switch[textId2].innerHTML = "Specifications")
  } else {
    app.switch[textId2].innerHTML === "Spécifications techniques"
      ? (app.switch[textId2].innerHTML = "Masquer")
      : (app.switch[textId2].innerHTML = "Spécifications techniques")
  }

};

// O P E N  T R I E S  /  S P E C S  I E  O P T I M I Z A T I O N
if (navigator.userAgent.indexOf(".NET") != -1) {
  if (
    window.location.href.includes("/try/") ||
    window.location.href.includes("/essai/")
  ) {
    app.tbanner.style.height = "auto";
  }

  for (var i = 0; i < app.readMore.length; i++) {
    app.readMore[i].addEventListener("click", tryDisplay);
  }

  for (var a = 0; a < app.productReadMore.length; a++) {
    app.productReadMore[a].addEventListener("click", specsDisplay);
  }
} else {
  if (
    window.location.href.includes("/try/") ||
    window.location.href.includes("/essai/")
  ) {
    app.tbanner.style.height = "100";
  }

  app.productReadMore.forEach(pl => {
    pl.addEventListener("click", specsDisplay);
  });

  app.readMore.forEach(el => {
    el.addEventListener("click", tryDisplay);
  });
}

// C O O K I E  B A N N E R
const acceptCookie = e => {
  if (document.cookie.indexOf("rgpd=") === -1) {
    app.cookie.classList.add("hide");

    document.cookie = "rgpd=true;";
  } else {
    return;
  }
};

const denyCookie = e => {
  if (document.cookie.indexOf("rgpd=") === -1) {
    app.cookie.classList.toggle("hide");

    document.cookie = "rgpd=false;";
  } else {
    return;
  }
};

const checkCookie = e => {
  if (document.cookie.indexOf("rgpd=true") !== -1 && app.cookie !== null) {
    app.cookie.classList.toggle("hide");
  } else if (document.cookie.indexOf("rgpd=false") !== -1) {
    app.cookie.classList.toggle("hide");
    document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = "_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie =
      "_gat_gtag_UA_139719103_1=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  }
};

window.addEventListener("load", checkCookie);

if (app.cookieYes !== null && app.cookieNo !== null) {
  app.cookieYes.addEventListener("click", acceptCookie);
  app.cookieNo.addEventListener("click", denyCookie);
}

// O P E N  M A P  P O P U P  V I A  L I S T

const showRev = e => {
  app.revPop.forEach(popup => {
    popup.classList.toggle("b-opened");
  });
};

app.revName.forEach(name => {
  name.addEventListener("click", showRev);
});

// H I D E  M A P  C R E D I T S
if (document.querySelector(".page-template-page-revendeur"))
  app.credit.classList.toggle("hide");

for (let i = 0; i < app.revPop.length; i++) {
  app.revPop[i].addEventListener("click", () => {
    console.log("coucou");
  });
}
